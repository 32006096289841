<template>
  <bar-chart
    title="Meilleurs RPMs"
    subTitle="par channel"
    :colors="colors"
    :series="series"
    :labels="labels"
    :loading="loading"
    :datalabels="false"
    :yAxis="yAxis"
    :formatter="formatter"
    outlined
  />
</template>

<script>
import debounce from "lodash/debounce";

import BarChart from "@/components/common/charts/BarChart";

import useAxios from "@/hooks/useAxios";

import { colors } from "@/utils/constants";
import { formatCurrency } from "@/utils/formatting";

export default {
  name: "ChannelRpmChart",
  components: {
    BarChart,
  },
  data() {
    return {
      loading: false,
      colors: [colors.rpm],
      series: [
        {
          name: "RPMs",
          data: [],
        },
      ],
      labels: [],
      formatter: (val) => formatCurrency(val),
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatCurrency(val),
          },
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await this.axiosGet(
          "/adn-post/channels/most-performing",
          {
            start_date: this.startDate,
            end_date: this.endDate,
            sites: this.sites.join(","),
            categories: this.categories.join(","),
            to_exclude: this.excludeCategories,
            channels: this.channels.join(","),
            period_type: this.periodType,
            sort_by: "rpm",
          }
        );

        this.labels = data.map((item) => item.channel);
        this.series = [
          {
            name: "RPMs",
            data: data.map((item) => item.rpm),
          },
        ];

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    excludeCategories() {
      return this.$store.getters["audience/getExcludeCategories"];
    },
    channels() {
      return this.$store.getters["audience/getChannels"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
