<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="4">
        <site-group-filter store="audience" />
      </v-col>
      <v-col cols="3">
        <date-filter store="audience" periodType="past-only"></date-filter>
      </v-col>
      <v-col cols="3">
        <v-select
          outlined
          v-model="periodType"
          :items="periodTypeList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Pour la période"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>

      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>

      <v-col cols="6">
        <site-filter store="audience" />
      </v-col>
      <v-col cols="6">
        <channel-filter />
      </v-col>

      <v-col cols="6">
        <category-filter store="audience" />
      </v-col>
      <v-col cols="6">
        <v-switch
          v-model="excludeCategories"
          label="A exclure"
          outlined
          dense
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCached } from "@mdi/js";

import useAxios from "@/hooks/useAxios";

import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import CategoryFilter from "@/components/common/filters/CategoryFilter.vue";
import ChannelFilter from "@/components/audience/perf-per-category/filters/ChannelFilter.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    CategoryFilter,
    ChannelFilter,
    DateFilter,
    SiteFilter,
    SiteGroupFilter,
    mdiCached,
  },
  data() {
    return {
      icons: {
        mdiCached,
      },
      periodTypeList: [
        { id: defaultPeriodType, name: "Articles vus" },
        { id: "articles_created", name: "Articles créés" },
      ],
    };
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "audience/updatePeriodType",
      param: URL_PARAM_NAMES["PeriodTypeFilter"],
      value: this.periodType,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "audience/updateExcludeCategories",
      param: URL_PARAM_NAMES["ToExclude"],
      value: this.periodType,
      // is_multiple: false,
      // is_integer: false,
      is_boolean: true,
      // dependsOn: undefined,
    });
  },
  methods: {
    validatePeriodType(periodType) {
      const allowedValues = this.periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType)
        ? periodType
        : defaultPeriodType;
    },
  },
  computed: {
    periodType: {
      get() {
        const storedValue = this.$store.getters["audience/getPeriodType"];
        const value = this.validatePeriodType(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("audience/updatePeriodType", value);
        }

        return value;
      },
      set(e) {
        this.$store.dispatch(
          "audience/updatePeriodType",
          this.validatePeriodType(e)
        );
      },
    },
    excludeCategories: {
      get() {
        return this.$store.getters["audience/getExcludeCategories"];
      },
      set(e) {
        this.$store.dispatch("audience/updateExcludeCategories", e);
      },
    },
  },
  watch: {
    periodType(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        URL_PARAM_NAMES["PeriodTypeFilter"],
        newValue
      );
    },
    excludeCategories(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        URL_PARAM_NAMES["ToExclude"],
        newValue
      );
    },
  },
};
</script>

<style></style>
