<template>
  <v-list-item link @click.native="$emit('exportExcel')" :disabled="disabled">
    <v-list-item-title>Export Excel</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "ExportExcelButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    excelData: {
      type: Blob,
      default: () => new Blob([""], { type: "text/plain" }),
    },
    filename: {
      type: String,
      default: "export.xlsx",
    },
  },
  data() {
    return {};
  },
  methods: {
    exportExcel() {
      if (this.disabled || this.excelData.length === 0) {
        return;
      }
      const downloadLink = document.createElement("a");
      const blob = new Blob([this.excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = this.filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  watch: {
    excelData() {
      this.exportExcel();
    },
  },
};
</script>
